import http from '../utils/request.js'

const belt = {
  getBeltInfo(id) {
    if (!id) id = 1
    const params = {
      production_line_id: id,
    }
    return http.post(`/screen/productionLineBoard`, params, {
      headers : {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },
  getPercent(id) {
    if (!id) id = 1
    const params = {
      production_line_id: id,
    }
    return http.post(`/screen/conveyorBeltPercent`, params, {
      headers : {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },
  getProcessLog(id, num) {
    if (!id) id = 0
    if (!num) num = 10
    const params = {
      conveyor_belt_id: id,
      limit: num
    }
    return http.post(`/screen/beltProcessInfo`, params, {
      headers : {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },
  getBeltProductivity() {
    const params = {
    }
    return http.post(`/screen/beltProductivity`, params, {
      headers : {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },
  getBeltStatus() {
    const params = {
    }
    return http.post(`/screen/beltStatus`, params, {
      headers : {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

}

export default belt;