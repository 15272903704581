<template>
  <div class="top-box">
    <div class="grid-box">
      <div v-for="item in items" :key="item.id" class="grid-item">
        <div class="item-top">{{ item.status }}</div>
        <div class="item-bottom">
          <countTo :startVal='old_item_num[item.id]' :endVal='item.num'
                   :duration='3000'></countTo>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "top",
  data() {
    return {
      items: [
        {id: 0, num: 0, status: ''},
        {id: 1, num: 0, status: ''},
        {id: 2, num: 0, status: ''},
        {id: 3, num: 0, status: ''},
        {id: 4, num: 0, status: ''},
      ],
      old_item_num: [0, 0, 0, 0, 0],
    }
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  watch: {
    info: {
      handler(newData) {
        this.old_item_num = this.items.map(item => item.num)
        this.items = [
          {id: 0, num: newData.worker_cnt, status: '工人数'},
          {id: 1, num: newData.machine_cnt, status: '设备数'},
          {id: 2, num: newData.order_cnt, status: '工单数'},
          {id: 3, num: 388, status: '工件数'},
          {id: 4, num: newData.stock_cnt, status: '库存数'},
        ]
      },
      deep: true
    }
  },
  methods: {}
})
</script>

<style scoped lang="scss">

.top-box {
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center; /* 在水平轴上居中 */
  align-items: center; /* 在纵轴上居中 */
  .grid-box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin: 20px 20px;
    height: 90%;
    width: 1800px;

    .grid-item {
      border-radius: 10px;
      margin: 10px 20px;
      //background-color: #999999;
      display: flex;
      flex-direction: column;

      .item-top {
        flex: 1;
        display: flex;
        margin-left: 10px;
        justify-content: flex-start; /* 在水平轴上居中 */
        align-items: center; /* 在纵轴上居中 */
        font-size: 20px;
        font-weight: bold;
        color: white;
      }

      .item-bottom {
        flex: 2;
        display: flex;
        justify-content: center; /* 在水平轴上居中 */
        align-items: center; /* 在纵轴上居中 */
        font-size: 36px;
        font-weight: bold;
        color: white;
      }
    }
  }
}
</style>
