<template>
  <div>
    <Chart :speed="speed2"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        data: [],
      },
      speed2:0
    };
  },
  components: {
    Chart,
  },
  props: {
    speed: {
      type: Number
    }
  },
  created() {
    // console.log('speed:',this.speed);
    this.speed2 = this.speed
  },
  watch: {
    speed: {
      handler: function (newVal) {
        this.speed2 = newVal
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>