<template>
  <div class="rt-box">
    <div class="bg-color-black">
      <div class="top-box">
        <div class="rt-title">{{ this.title }}</div>
        <el-select v-model="value" placeholder="全部" @change="handleSelectChange">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="board-content">
        <dv-scroll-board class="dv-scr-board" :config="config"/>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import belt from "@/api/belt"

export default defineComponent({
  name: "rightTop",
  data() {
    return {
      title: "加工信息",
      processLog:[],
      options: [
        {
          value: '0',
          label :'全部'
        },
        {
          value: '1',
          label: 'belt1'
        },
        {
          value: '2',
          label: 'belt2'
        },
        {
          value: '3',
          label: 'belt3'
        },
        {
          value: '4',
          label: 'belt4'
        },
        {
          value: '5',
          label: 'belt5'
        },
      ],
      value: '0',
      config: {
        header: ["设备", "加工开始", "加工结束", "加工时间"],
        data: [],
        rowNum: 5,
        headerHeight: 35,
        headerBGC: "#0f1325",
        oddRowBGC: "#0f1325",
        evenRowBGC: "#171c33",
        index: false,
        columnWidth: [150, 150, 150, 150],
        align: ["center", "center", "center", "center"],
      }
    }
  },
  // props: {
  //   processLog : {
  //     type: Array,
  //     required: true,
  //   },
  // },
  methods: {
    handleSelectChange() {
      this.$emit('select-change', this.value);
      // console.log(this.value)
      this.fetchData(this.value)
    },
    formatDateTime(dateTimeString) {
      // 创建一个 Date 对象，将时间字符串转换为日期对象
      const dateTime = new Date(dateTimeString);

      // 获取日期、小时和分钟
      const mouth = dateTime.getMonth() + 1;
      const date = dateTime.getDate();
      const hours = dateTime.getHours();
      const minutes = dateTime.getMinutes();
      const sec = dateTime.getSeconds();

      // 格式化输出
      const formattedDateTime = `${mouth}-${date}  ${hours}:${minutes}:${sec}`;

      return formattedDateTime;
    },
    calculateTime(time) {
      const nsecs = Math.abs(time);
      const secs = Math.floor(nsecs / 1e9)
      return `${secs}秒`
    },
    fetchData(value) {
      belt.getProcessLog(value).then(
        (response) => {
          // console.log(response.data.data)
          this.processLog = response.data.data
        }
      )
      this.config = {...this.config}
    },
  },
  created() {
    this.fetchData(0);
  },
  watch: {
    processLog: {
      handler: function(newVal) {
        this.config.data = newVal.map(item => {
          return [item.conveyor_belt_name, 
                  this.formatDateTime(item.process_start), 
                  this.formatDateTime(item.process_stop), 
                  this.calculateTime(item.process_rate)]
        })
        this.config = {...this.config}
      },
      deep: true
    }
  }
})
</script>

<style scoped lang="scss">
$box-height: 440px;
$box-width: 622px;
.rt-box {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 35px;
    border-radius: 10px;
  }

  .top-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .rt-title {
      padding: 5px 0 0 20px;
      font-size: 24px;
      font-weight: bold;
      color: #25a18e;
    }
  }

  .board-content {
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .dv-scr-board {
      width: $box-width -50px;
      height: 350px;
    }
  }
}
</style>
