<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data() {
    return {
      cdata: {
        category: [],
        data: [],
      }
    };
  },
  components: {
    Chart,
  },
  props: {
    percent: {
      type: Array,
      required: true,
    }
  },
  mounted() {
  },
  watch: {
    percent: {
      handler: function (newVal) {
        // console.log("----------*newVal*--------------")
        // console.log(newVal)
        this.cdata.category = []
        this.cdata.data = [[], []]
        newVal.forEach((item, index) => {
          this.cdata.category[index] = item.conveyor_belt_name
          this.cdata.data[0][index] = item.run
          this.cdata.data[1][index] = item.close
        })
      },
      deep: true
    }
  }
}
</script>