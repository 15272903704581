<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        category: [],
        data: [],
      }
    };
  },
  components: {
    Chart,
  },
  props: {
    processedNum: {
      type: Array,
      required: true,
    },
  },
  watch: {
    processedNum: {
      handler: function (newVal) {
        this.cdata.category = []
        this.cdata.data = []
        newVal.forEach((item, index) => {
          this.cdata.category[index] = item.conveyor_belt_name
          this.cdata.data[index] = item.processed_num
        })
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
