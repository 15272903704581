<template>
  <div class="mid-box">
    <div class="bg-color-black">
      <div class="mid-desc">
        <div class="mid-title">{{ this.title }}</div>
      </div>
      <div class="container">
        <div v-for="item in belts" :key="item.conveyor_belt_id" class="box">
          <div class="left">
            <p class="item">传送带名称：{{ item.conveyor_belt_name }}</p>
            <p class="item">加工数量：{{ item.processed_num }}</p>
            <p class="item">传送带状态：{{ getStatus(item.status) }}</p>
            <p class="item">电机速度：{{ item.speed }}</p>
          </div>
          <div class="mid_speed">
            <Mid :speed="item.speed" />
          </div>
          <div class="right">
            <div class="right-up">
              <button class="action-button" @click="change_status(item)">{{ change_speed_name(item.status) }}</button>
            </div>
            <div class="right-down">
              <button class="action-button" @click="change_speed_windows_on(item)">调整速度</button>
            </div >
            <el-dialog class="speed_dialog" :visible.sync="speed_change_windows" :append-to-body="true" width="30%">
              <div>
                <h3>调整速度</h3>
                <el-slider
                  v-model="speed_temp"
                  :min="0"
                  :max="200"
                  :step="1"
                  show-input
                  @change="updateSpeed()"
                />
                <p>当前速度: {{ speed_temp }}</p>
              </div>
            </el-dialog>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import Mid from "_c/echart/belt/mid/midchart/index.vue"

export default defineComponent({
  name: "mid",
  data() {
    return {
      title: "传送带信息",
      belts: [
        // for test ----- BEGIN ----- DELETE
        {
          conveyor_belt_id: 1,
          conveyor_belt_name: "传送带1",
          processed_num: 100,
          status: 1,
          speed: 50,
        },
        {
          conveyor_belt_id: 2,
          conveyor_belt_name: "传送带2",
          processed_num: 200,
          status: 0,
          speed: 30,
        },
        {
          conveyor_belt_id: 3,
          conveyor_belt_name: "传送带3",
          processed_num: 150,
          status: 1,
          speed: 40,
        },
        // for test ----- END ----- DELETE 
      ],
      animationSpeed: 1,
      ringColor: '#e74c3c',
      speed_change_windows: false,
      change_item_id: 0,
      speed_temp: 0
    }
  },
  props: {
    beltStatus: {
      type: Array,
      required: true,
    }
  },
  components: {
    Mid
  },
  watch: {
    // for test ----- BEGIN ----- UNCOMMENT
    // beltStatus: {
    //   handler: function (newVal) {
    //     this.belts = newVal
    //     this.belts = {...this.belts}
    //   },
    //   deep: true
    // }
    // for test ----- END ----- UNCOMMENT
  },
  methods: {
    getStatus(status) {
      if (status == 1) {
        return "运行";
      }
      else {
        return "关机";
      }
    },
    getSpeed(sp) {
      this.speed = sp;
    },
    change_status() {
      // FIXME

    },
    change_speed_name(status) {
      return status == 1 ? "关闭传送带" : "开启传送带";
    },
    change_speed_windows_on(item) {
      this.change_item_id = item.conveyor_belt_id;
      this.speed_temp = item.speed;
      this.speed_change_windows = true;
    },
    updateSpeed(){
      // FIXME

    }
  }
})
</script>

<style scoped lang="scss">
$box-height: 880px;
$box-width: 622px;

.mid-box {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .mid-desc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    .mid-title {
      padding: 5px 0 0 20px;
      font-size: 24px;
      font-weight: bold;
      color: #25a18e;
    }

    .mid-detail {
      padding: 5px 0 0 20px;
      cursor: pointer;
      //padding: 5px 20px 0 0;
      font-size: 20px;
      color: #25a18e;
    }
  }

}

.container {
  .box {
    display: flex;
    border: 1px solid white;
    border-radius: 15px;
    margin: 10px;

    .left {
      width: 40%;
      font-size: 20px;
      font-weight: bold;

      .item {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 20px;
      }

    }

    .mid_speed {
      width: 30%;
      align-items: center;
      justify-content: center;
    }

    .right {
      width: 30%;
      color: #25a18e;
      display: flex;
      flex-direction: column;
      /* 垂直排列 */
      justify-content: center;
      /* 垂直居中 */
      align-items: center;
      /* 水平居中 */

      .right-up,
      .right-down {
        width: 100%;
        /* 占满父容器宽度 */
        height: 40%;
        display: flex;
        /* Use flexbox */
        justify-content: center;
        /* Center horizontally */
        align-items: center;
        /* Center vertically */
        margin: 5px 0;
        /* 上下间隔 */
      }
    }
  }

  .action-button {
    width: 70%;
    /* Adjust width as needed */
    padding: 10px;
    margin: 5px 0;
    /* Spacing between buttons */
    background-color: #25a18e;
    /* Button color */
    color: white;
    /* Text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;

    &:hover {
      background-color: #1e8c6e;
      /* Darker shade on hover */
    }
  }

}
</style>
