<template>
  <div>
    <Echart
        :options="options"
        id="RightTopRightChart"
        height="360px"
        width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart/index.vue'

export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            top: '12%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: newData.category,
              axisLabel: {
                textStyle: {
                  fontSize: 16,
                  fontWeight: 'bold'
                },
                interval: 0,
              },
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                textStyle: {
                  fontSize: 16
                },
                formatter: function (value) {
                  return value;
                }
              },
              splitLine: {show: true},
            },
          ],
          series: [
            {
              name: '优品率',
              type: 'bar',
              barWidth: '60%',
              data: newData.data,
              label: {
                show: true,
                position: 'top',
                color: 'white',
                fontSize: 16,
                formatter: function (params) {
                  return params.value;
                },
              },
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                }
              },
            }
          ],
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>
