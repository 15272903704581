<template>
  <div id="leftTop">
    <div class="bg-color-black">
      <div class=" top-box">
        <div class="chart-title">{{ this.title }}</div>
      </div>
      <div>
        <leftTopChart :processedNum="processedNum"/>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import LeftTopChart from "_c/echart/belt/left/leftChart/index.vue";

export default defineComponent({
  name: "leftTop",
  data() {
    return {
      title: "工件加工数量",
    }
  },
  props: {
    processedNum: {
      type: Array,
      required: true,
    },
  },
  components: {
    LeftTopChart
  },
  methods: {
  }
})
</script>

<style scoped lang="scss">
$box-height: 465px;
$box-width: 100%;
#leftTop {
  padding: 10px 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 35px;
    border-radius: 10px;
  }

  .top-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .chart-title {
      margin-top: 10px;
      padding: 0 0 0 20px;
      font-size: 24px;
      font-weight: bold;
      color: #25a18e;
    }
  }
}
</style>
