<template>
  <div class="lb-box">
    <div class="bg-color-black">
      <div class="lb-content">
        <div class="lb-desc">
          <div class="lb-title">{{ this.title }}</div>
        </div>
        <div>
          <LeftBottomChart :percent="this.percent"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import LeftBottomChart from '_c/echart/belt/left/percent/index.vue'

export default defineComponent({
  name: "leftBottom",
  data() {
    return {
      title: "传送带效率",
    }
  },
  props: {
    percent: {
      type: Array,
      required: true,
    }
  },
  components: {
    LeftBottomChart
  },
})
</script>

<style scoped lang="scss">
$box-height: 440px;
$box-width: 622px;
.lb-box {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;

  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }

  .lb-content {
    display: flex;
    height: 300px;
    flex-direction: column;
    margin-bottom: 20px;

    .lb-desc {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;

      .lb-title {
        padding: 5px 0 0 20px;
        font-size: 24px;
        font-weight: bold;
        color: #25a18e;
        margin-top: 10px;
      }
    }

    .grid-content {
      flex: 4;
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .grid-item {
        display: flex;
        flex-direction: column;

        .item-top {
          flex: 1;
          display: flex;
          font-weight: bold;
          font-size: 42px;
          align-items: center; /* 在纵轴上居中 */
          justify-content: center; /* 在水平轴上居中 */
          width: 100%;
          height: 100%;
        }

        .item-bottom {
          flex: 1;
          display: flex;
          align-items: flex-start; /* 在纵轴上居中 */
          justify-content: center; /* 在水平轴上居中 */
          font-size: 22px;
          font-weight: bold;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

}
</style>
